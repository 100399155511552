import cn from "clsx"
import s from "./Input.module.scss"

const Input = ({
  label,
  invertColor,
  size = "small",
  className,
  innerLabel,
  showLabel,
  transparentBackground,
  type,
  isDisabled,
  ...props
}) => {
  return (
    <div
      className={cn(
        s.container,
        invertColor && s.invertColor,
        type == "number" && s.isNumber,
        transparentBackground && s.transparent,
      )}
    >
      {showLabel && <label className={s.label}>{label}</label>}
      <input
        className={cn(s.input, className, size == "large" && s.large)}
        type={type}
        disabled={isDisabled}
        {...props}
      />
      {innerLabel && <span className={s.innerLabel}>{label}</span>}
    </div>
  )
}

export default Input
