import { useState } from "react"
import { useStore } from "../../../store"
import Button from "../../common/Button"
import Modal from "../../common/Modal"
import s from "./Results.module.scss"

const Results = () => {
  const [totalParking] = useStore(({ totalParking }) => [totalParking])
  const [totalRequired] = useStore(({ totalRequired }) => [totalRequired])
  const [delta] = useStore(({ delta }) => [delta])
  const [totalCo2] = useStore(({ totalCo2 }) => [totalCo2])
  const [totalCost] = useStore(({ totalCost }) => [totalCost])
  const [guestParking] = useStore(({ guestParking }) => [guestParking])
  const [disabledParking] = useStore(({ disabledParking }) => [disabledParking])
  const [showContactModal, setShowContactModal] = useState(false)

  return (
    <div className={s.container}>
      <div>
        <h2>Resultat</h2>
        <ul className={s.numbers}>
          <li>
            <span>Total parkering</span> <span>{totalParking}</span>
          </li>
          <li>
            <span>Total parkeringsbehov</span> <span>{totalRequired} </span>
          </li>
          {disabledParking && (
            <li>
              <span style={{ marginLeft: "1em", fontSize: "14px" }}>
                Rörelsehindrade (Antal)
                {}
              </span>{" "}
              <span>{Math.ceil(totalRequired * 0.02)} </span>
            </li>
          )}
          {guestParking && (
            <li>
              <span style={{ marginLeft: "1em", fontSize: "14px" }}>
                Besöksparkering (Antal)
              </span>{" "}
              <span>{Math.ceil(totalRequired / 10)} </span>
            </li>
          )}
          <li>
            <span>
              <b>Differens</b>
            </span>
            <span className={delta >= 0 ? "text-green" : "text-red"}>
              {delta}
            </span>
          </li>
          <li>
            <span>Total co2 (Ton)</span> <span>{totalCo2} </span>
          </li>
          <li>
            <span>Total investering (MSEK) </span> <span>{totalCost} </span>
          </li>
        </ul>
      </div>

      <Button
        style={{ marginTop: "1em" }}
        onClick={() => setShowContactModal(true)}
      >
        BLI KONTAKTAD
      </Button>

      <Modal
        title="Kontakta oss"
        show={showContactModal}
        onHide={() => setShowContactModal(false)}
      >
        Lorem ipsum
      </Modal>
    </div>
  )
}

export default Results
