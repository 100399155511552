import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
// import { Modal } from "react-bootstrap"
import axios from "axios"
import { MdDelete } from "react-icons/md"
import Button from "./common/Button"
import Modal from "./common/Modal"
import Tooltip from "./common/Tooltip"
import Dropdown from "./common/Dropdown"
import { toast } from "react-toastify"
import { CiSettings } from "react-icons/ci"
import { CiShare2 } from "react-icons/ci"
import { FaCopy } from "react-icons/fa"

function DeleteProjectModal({ projectId, onClose, editDisabled }) {
  const [show, setShow] = useState(false)
  const [showCopy, setShowCopy] = useState(false)
  const [projectName, setProjectName] = useState("")
  const [toggleDropdown, setToggleDropdown] = useState(false)
  const navigate = useNavigate()

  const handleClose = () => {
    setShow(false)
    setProjectName("") // Reset the project name on modal close
    onClose()
  }

  const copyToClipboard = () => {
    const url = window.location.href
    console.log(url)
    navigator.clipboard
      .writeText(url)
      .then(() => {
        toast.success("Länken har kopierats!")
      })
      .catch((err) => {
        toast.success("Det gick inte att kopiera länken, försök igen")
      })
  }

  const handleShow = () => setShow(true)
  const handleShowCopy = () => setShowCopy(true)

  const copyProject = async () => {
    try {
      // Send a POST request to the server
      const response = await axios.post(
        `${process.env.REACT_APP_PARKYRIA_BACKEND_URL}/api/projects/copy/${projectId}`,
      )
      console.log("Server Response:", response.data)
      setShowCopy(false)
      toast.success("Projektet har kopierats")
      // setTimeout(() => {
      //   navigate("/")
      // }, 2000)
    } catch (error) {
      toast.error("Något gick fel! Försök igen.")
      console.error("Error submitting the project:", error)
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    // Here you can handle the form submission
    console.log("Project Name:", projectName)

    try {
      // Send a POST request to the server
      const response = await axios.delete(
        `${process.env.REACT_APP_PARKYRIA_BACKEND_URL}/api/projects/${projectId}`,
      )

      // Handle the response accordingly
      console.log("Server Response:", response.data)

      handleClose() // Close the modal after submission
      toast.success("Projektet har tagits bort!")
      setTimeout(() => {
        navigate("/")
      }, 2000)
    } catch (error) {
      toast.error("Något gick fel! Försök igen.")
      console.error("Error submitting the project:", error)
    }
  }

  const handleInputChange = (e) => {
    setProjectName(e.target.value)
  }

  return (
    <>
      <div>
        <Button
          onClick={() => setToggleDropdown(!toggleDropdown)}
          ghost
          invertColor={true}
          autoWidth={true}
        >
          <CiSettings fontSize="24px" />
        </Button>
        <Dropdown show={toggleDropdown} position="bottom">
          <li>
            <Button ghost autoWidth={true} onClick={() => copyToClipboard()}>
              <CiShare2 fontSize="24px" style={{ marginRight: "0.25em" }} />
              Kopiera länk
            </Button>
          </li>
          {!editDisabled && (
            <li>
              <Button ghost autoWidth={true} onClick={handleShow}>
                <MdDelete fontSize="24px" /> Ta bort projekt
              </Button>
            </li>
          )}
          <li>
            <Button ghost autoWidth={true} onClick={handleShowCopy}>
              <FaCopy fontSize="24px" /> Kopiera projekt
            </Button>
          </li>
        </Dropdown>
      </div>
      <Modal
        title="Ta bort projekt"
        show={show}
        onHide={handleClose}
        buttons={[
          <Button onClick={handleSubmit}>Ta bort</Button>,
          <Button outline onClick={handleClose}>
            Avbryt
          </Button>,
        ]}
      >
        Är du säker på att du vill ta bort detta projekt?
      </Modal>
      <Modal
        title="Kopiera projekt"
        show={showCopy}
        onHide={() => setShowCopy(false)}
        buttons={[
          <Button onClick={copyProject}>Kopiera</Button>,
          <Button outline onClick={() => setShowCopy(false)}>
            Avbryt
          </Button>,
        ]}
      >
        Vill du kopiera detta projekt? Projektet kommer hamna i din lista över
        projekt.
      </Modal>
    </>
  )
}

export default DeleteProjectModal
