import cn from "clsx"
import s from "./Message.module.scss"
import { MdError } from "react-icons/md"

const Message = ({ children, type = "success", ...props }) => {
  return (
    <div
      className={cn(
        s.container,
        type == "success" && s.success,
        type == "error" && s.error,
      )}
      {...props}
    >
      {type == "error" && <MdError />}
      {children}
    </div>
  )
}

export default Message
