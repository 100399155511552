import { useState } from "react"
import s from "./Tooltip.module.scss"
import cn from "clsx"
import { MdInfo } from "react-icons/md"

const Tooltip = ({
  text,
  children,
  showIcon,
  yPosition = "top",
  xPosition = "left",
}) => {
  const [isHovered, setIsHovered] = useState(false)

  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className={s.container}
    >
      {showIcon && (
        <div className={s.icon}>
          <MdInfo />
        </div>
      )}

      {children}
      {isHovered && (
        <div
          className={cn(
            s.tooltip,
            yPosition == "top" && s.top,
            yPosition == "bottom" && s.bottom,
            xPosition == "left" && s.left,
            xPosition == "right" && s.right,
          )}
        >
          {text}
        </div>
      )}
    </div>
  )
}

export default Tooltip
