import cn from "clsx"
import s from "./Modal.module.scss"
import { useEffect } from "react"
import { MdClose } from "react-icons/md"
import { createPortal } from "react-dom"

const Modal = ({ title, children, buttons, show, onHide }) => {
  function handleEscapeClose(e) {
    if (e.key === "Escape") {
      onHide()
    }
  }

  useEffect(() => {
    document.addEventListener("keydown", handleEscapeClose, false)

    return () => {
      document.removeEventListener("keydown", handleEscapeClose, false)
    }
  }, [])
  return (
    <>
      {show && (
        <>
          {createPortal(
            <>
              <div className={s.overlay} onClick={onHide}></div>
              <div className={s.container}>
                <div className={s.modal}>
                  <div className={s.header}>
                    <h4 className={s.title}>{title}</h4>
                    <button
                      className={s.close}
                      onClick={onHide}
                      aria-label="Stäng"
                    >
                      <MdClose />
                    </button>
                  </div>
                  <div className={s.content}> {children}</div>

                  {buttons && <div className={s.buttons}>{buttons}</div>}
                </div>
              </div>
            </>,
            document.body,
          )}
        </>
      )}
    </>
  )
}

export default Modal
