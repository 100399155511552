import React from "react"
import {
  MapContainer,
  TileLayer,
  GeoJSON,
  useMap,
  Marker,
  ZoomControl,
} from "react-leaflet"
import { icon } from "leaflet"
import "leaflet/dist/leaflet.css"

const API_URL = process.env.REACT_APP_API_URL

const ICON = icon({
  iconUrl: "/marker-icon.png",
  iconSize: [32, 32],
});
// Component to update the map's center
const ChangeView = ({ center, zoom }) => {
  const map = useMap()
  map.setView(center, zoom)
  return null
}

const Map = ({ coordinates, policy }) => {
  const [longitude, latitude] = coordinates
  const defaultProps = {
    center: {
      lat: latitude,
      lng: longitude,
    },
    zoom: 14,
  }

  const DARK_GREEN = [0, 255, 0] // RGB values for dark green
  //const LIGHT_GREEN = [144, 238, 144];  // RGB values for light green
  const LIGHT_GREEN = [0, 100, 0] // RGB values for light green

  const interpolateColor = (value, min, max, color1, color2) => {
    const ratio = (value - min) / (max - min)
    const r = Math.round(color1[0] + (color2[0] - color1[0]) * ratio)
    const g = Math.round(color1[1] + (color2[1] - color1[1]) * ratio)
    const b = Math.round(color1[2] + (color2[2] - color1[2]) * ratio)
    return `rgb(${r}, ${g}, ${b})`
  }

  const styleFunction = (feature) => {
    // Parsing the KONTOR string to a number
    // const value = parseInt(feature.properties.construction.find(c => c.name === "office").density, 10)

    const value = parseInt(policy.features.findIndex((f) => f === feature) + 1 ,10) * 5
    // Handle cases where KONTOR might not be a valid number
    if (isNaN(value)) {
      return {
        fillColor: "#FFFFFF", // Default color if KONTOR is not a valid number
        fillOpacity: 1,
        weight: 1,
      }
    }
    return {
      fillColor: interpolateColor(value, 7, 16, DARK_GREEN, LIGHT_GREEN),
      fillOpacity: 0.6,
      weight: 1,
    }
  }

  return (
    <MapContainer
      center={[latitude, longitude]}
      zoom={14}
      zoomControl={false}
      style={{ height: "1vh%", width: "100%" }}
    >
      <ChangeView center={[latitude, longitude]} zoom={14} />

      <ZoomControl position="bottomright" />

      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <Marker icon={ICON} position={[latitude, longitude]}></Marker>
      {policy && (
        <GeoJSON key={policy.name} data={policy} style={styleFunction} />
      )}
    </MapContainer>
  )
}

export default Map
