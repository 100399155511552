import TextArea from "../common/TextArea"
import { useDebouncedCallback } from "use-debounce"

const Notes = ({ project, onProjectChange, editDisabled }) => {
  const debounced = useDebouncedCallback((value) => {
    const newProject = {
      ...project,
      notes: value,
    }

    onProjectChange(newProject)
  }, 3000)

  return (
    <>
      <div className="toolbar-box">
        <h4 className="mt-10">Anteckningar</h4>
        <table className="toolbar-table">
          <tbody>
            <tr>
              <td>
                <TextArea
                  rows="5"
                  isDisabled={editDisabled}
                  placeholder="Skriv dina anteckningar..."
                  maxLength="1000"
                  className="input"
                  type="textarea"
                  label="Anteckningar"
                  defaultValue={project.notes}
                  onChange={(e) => debounced(e.target.value)}
                  invertColor
                  transparentBackground
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  )
}

export default Notes
