import axios from "axios"

const initPolicy = async (commune, setPolicy) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_PARKYRIA_BACKEND_URL}/api/policies/${commune}`,
    )
    setPolicy(response.data)
  } catch (e) {
    console.error("Error fetching policies: ", e)
  }
}

const getPolicy = async (commune) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_PARKYRIA_BACKEND_URL}/api/policies/${commune}`,
    )
    return response.data
  } catch (e) {
    console.error("Error fetching policies: ", e)
  }
}

const initProjects = async (setProjects) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_PARKYRIA_BACKEND_URL}/api/projects`,
    )
    setProjects(response.data)
  } catch (e) {
    console.error("Error fetching projects: ", e)
  }
}

const saveProject = async (newProject, setProject) => {
  // First update the local version
  setProject(newProject)

  try {
    // Save project and update local verision with saved version
    const response = await axios.put(
      `${process.env.REACT_APP_PARKYRIA_BACKEND_URL}/api/projects/${newProject._id}`,
      newProject,
    )

    const policy = await getPolicy(response.data.commune)
    const zone = getZoneFromPolicy(policy, newProject)

    response.data.construction = response.data.construction.map((c) => {
      var policy = zone.properties.construction.find((p) => p.name == c.type)

      return {
        id: c.id,
        type: c.type,
        desc: policy.displayName.sv,
        value: c.value,
        unit: policy.unit.sv,
        required:
          (c.value * policy.density) /
          policy.buildingAreaMeasurement,
        density: policy.density,
        buildingAreaMeasurement: policy.buildingAreaMeasurement,
      }
    })

    setProject(response.data)
  } catch (e) {
    // TODO: Show user that project change could not be saved.
    console.error("Error fetching projects: ", e)
  }
}

const getZoneFromPolicy = (policy, project) => {
  const zones = policy.features.filter(
    (feature) => feature.properties.zone === project.zone,
  )
  return zones.length == 1 ? zones[0] : null
}

const initProjectAndZone = async (id, setProject, setZone, setPolicy) => {
  try {
    // Save project and update local verision with saved version
    const response = await axios.get(
      `${process.env.REACT_APP_PARKYRIA_BACKEND_URL}/api/projects/${id}`,
    )
    const project = response.data

    const policy = await getPolicy(project.commune)
    const zone = getZoneFromPolicy(policy, project)

    project.construction =
      project.construction && project.construction.length > 0
        ? project.construction.map((c) => {
            var policy = zone.properties.construction.find(
              (p) => p.name == c.type,
            )

            return {
              id: c.id,
              type: c.type,
              desc: policy.displayName.sv,
              value: c.value,
              unit: policy.unit.sv,
              required:
                (c.value * policy.density) /
                policy.buildingAreaMeasurement,
              density: policy.density,
              buildingAreaMeasurement: policy.buildingAreaMeasurement,
            }
          })
        : zone.properties.construction.map((c) => {
            return {
              type: c.name,
              desc: c.displayName.sv,
              value: 0,
              unit: c.unit.sv,
              required: 0,
              density: c.density,
              buildingAreaMeasurement: c.buildingAreaMeasurement,
            }
          })

    setProject(project)
    setZone(zone)
    setPolicy(policy)
  } catch (e) {
    // TODO: Show user that project change could not be saved.
    console.error("Error fetching projects: ", e)
  }
}

export { initPolicy, initProjects, saveProject, initProjectAndZone }
