import { useState, useEffect } from "react"
import { Container, Row, Col, Nav } from "react-bootstrap"
import "bootstrap/dist/css/bootstrap.min.css"
import "../css/App.css"
import Dashboard from "../pages/Dashboard"
import PublicRoutes from "../routing/PublicRoutes"
import axios from "axios"
import AuthService from "../modules/AuthService"

axios.interceptors.request.use(
  (config) => {
    const token = AuthService.getToken()
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)

// Adding a response interceptor
axios.interceptors.response.use(
  (response) => {
    // This block is for handling successful responses (status code 200-299)
    return response
  },
  (error) => {
    // This block handles errors
    if (error.response && error.response.status === 401) {
      // Detected a 401 Unauthorized response
      console.log("Authentication failed, token might have expired")

      // Perform the logout operation
      AuthService.logout() // Clear token or reset auth state

      // if (window.location.hostname.includes("localhost")) {
      //   if (window.location.pathname !== "/login") {
      //     window.location.href = "/login"
      //   } else {
      if (window.location.pathname != "/logout") {
        window.location.href = `https://www.parkyria.se/sign-in?redirect=${encodeURIComponent(window.location.href)}`
      }
      //   }
      // }
    }

    // Return any other error without modification
    return Promise.reject(error)
  },
)

const App = () => {
  return <PublicRoutes />
}

export default App
