import axios from "axios"
import Cookies from "js-cookie"
// import jwt from "jsonwebtoken"

const AuthService = {
  login: async ({ email, password }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_PARKYRIA_BACKEND_URL}/account/login`,
        { email, password },
      )
      if (response.data.accessToken) {
        localStorage.setItem("token", response.data.accessToken)

        if (window.location.hostname.includes("localhost")) {
          Cookies.set("parkyria-token", response.data.accessToken, {
            path: "/",
          })
        } else {
          Cookies.set("parkyria-token", response.data.accessToken, {
            domain: ".parkyria.se",
            path: "/",
            secure: true,
            sameSite: "None",
          })
        }

        const res = await fetch(
          `${process.env.REACT_APP_PARKYRIA_BACKEND_URL}/account`,
          {
            headers: {
              Authorization: `Bearer ${response.data.accessToken}`,
            },
          },
        )
        const account = await res.json()
        if (window.location.hostname.includes("localhost")) {
          Cookies.set("parkyria-user", account.id, {
            path: "/",
          })
        } else {
          Cookies.set("parkyria-user", account.id, {
            domain: ".parkyria.se",
            path: "/",
            secure: true,
            sameSite: "None",
          })
        }
      }
      return response.data
    } catch (error) {
      throw error
    }
  },
  verifyToken: async () => {
    try {
      const response = await axios.post("/verify_token")
      return response.data
    } catch (error) {
      throw error
    }
  },
  getUser: () => {
    return Cookies.get("parkyria-user")
  },
  getToken: () => {
    return Cookies.get("parkyria-token")
  },
  logout: () => {
    console.log(window.location.hostname)
    if (window.location.hostname.includes("localhost")) {
      Cookies.remove("parkyria-token", { path: "/", domain: "localhost" })
      Cookies.remove("parkyria-user", { path: "/", domain: "localhost" })
      window.location.href = `https://www.parkyria.se`
    } else {
      Cookies.remove("parkyria-token", { path: "/", domain: ".parkyria.se" })
      Cookies.remove("parkyria-user", { path: "/", domain: ".parkyria.se" })
    }
  },
}

export default AuthService
