import { Container } from "react-bootstrap"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import axios from "axios"

import AuthService from "../modules/AuthService"

const Logout = ({ setLoggedIn }) => {
  const navigate = useNavigate()

  useEffect(() => {
    AuthService.logout()
    setLoggedIn(AuthService.getToken())

    if (window.location.hostname.includes("localhost")) {
      window.location.href = "/"
    } else {
      window.location.href = `https://www.parkyria.se`
    }
  }, [])

  return (
    <Container fluid className="p-0">
      <div className="text-center">
        <h1>Logout</h1>
      </div>
    </Container>
  )
}

export default Logout
