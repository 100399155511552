import { Col, Container, Row } from "react-bootstrap"
import s from "./Layout.module.scss"
import { MdAccountCircle, MdHome } from "react-icons/md"
import { useState } from "react"
import Dropdown from "../common/Dropdown"
import { Link } from "react-router-dom"

const Layout = ({ children }) => {
  const [toggleDropdown, setToggleDropdown] = useState(false)
  return (
    <main>
      <div className={s.navWrapper}>
        <div className={s.navbar}>
          <div className={s.navbarContent}>
            <div>
              <Link to="/">
                <div style={{ width: "150px", marginLeft: "1em" }}>
                  <img
                    src="/parkyria-logo.svg"
                    width="100%"
                    loading="lazy"
                    alt="Parkyria logo"
                  />
                </div>
              </Link>
            </div>
            {/* <ul className={s.navItems} style={{ marginBottom: "0" }}>
              <li style={{ display: "flex", alignItems: "center" }}>
                <h2
                  style={{
                    fontSize: "15px",
                    fontWeight: 500,
                    marginBottom: "0",
                  }}
                  _hover={{
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  Marknadsplats
                </h2>
              </li>
            </ul> */}
            <div style={{ position: "relative" }}>
              <button
                style={{ padding: "1em 1.75em", borderRadius: "1em" }}
                aria-label="Mitt konto"
                onClick={() => setToggleDropdown(!toggleDropdown)}
              >
                MITT KONTO
              </button>
              <Dropdown show={toggleDropdown} position="bottom">
                <li>
                  <Link to="/logout">Logga ut</Link>
                </li>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
      <div>{children}</div>
    </main>
  )
}

export default Layout
