import { Container } from "react-bootstrap"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import axios from "axios"

import AuthService from "../modules/AuthService"
import Button from "../modules/common/Button"
import Input from "../modules/common/Input"
import Login from "../modules/Login"

const LoginPage = ({ setLoggedIn }) => {
  return <Login setLoggedIn={setLoggedIn} />
}

export default LoginPage
