import cn from "clsx"
import s from "./Dropdown.module.scss"

/* Requires a wrapper with position: relative */

function Dropdown({ show, position = "bottom", children }) {
  return (
    <>
      {show && (
        <div className={cn(s.container, position == "top" && s.top)}>
          <ul className={s.list}>{children}</ul>
        </div>
      )}
    </>
  )
}

export default Dropdown
