import { Container } from "react-bootstrap"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import axios from "axios"
import s from "./Register.module.scss"
import Input from "../common/Input"
import Button from "../common/Button"
import cn from "clsx"
import Message from "../common/Message"

const Register = ({ animate, setShowRegister }) => {
  const [credentials, setCredentials] = useState({
    email: "",
    firstName: "",
    lastName: "",
    password: "",
    password_confirmation: "",
  })
  const [errors, setErrors] = useState({})
  const navigate = useNavigate()

  const handleSubmit = async (e) => {
    e.preventDefault()

    if(validateForm()) {
    try {
      const response = await axios.post(`${process.env.REACT_APP_PARKYRIA_BACKEND_URL}/account/register`, credentials)
      setShowRegister(false)
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.errors
      ) {
        console.error(error.response.data.errors)
        setErrors(error.response.data.errors)
      } else {
        // Handle other types of errors (network error, no response, etc.)
        console.error(error.message)
      }
    }
    }
  }

  const validateForm = () => {

    const errors = []
    setErrors({})

    if(credentials.password !== credentials.password_confirmation) {
      errors.push("test")
      setErrors({password: ["Lösenorden matchar inte"]})
    }

    return errors.length === 0
  }
  const onChange = (type, e) => {
    setCredentials((old) => ({
      ...old,
      [type]: e.target.value,
    }))
  }

  return (
    <div className={cn(s.container, animate && s.animate)}>
      <div className={s.wrap}>
        <h1>Registrera konto</h1>
        <form onSubmit={handleSubmit}>
          <div className="form-grid form-grid--large-gutter">
            <div>
              <Input
                type="email"
                onChange={(e) => onChange("email", e)}
                value={credentials.email}
                placeholder="Din e-post"
                size="large"
                label="E-post"
                showLabel
              />
            </div>
            <div>
              <Input
                type="text"
                onChange={(e) => onChange("firstName", e)}
                value={credentials.firstName}
                size="large"
                placeholder="Ditt förnamn"
                label="Förnamn"
                showLabel
              />
            </div>
            <div>
              <Input
                type="text"
                onChange={(e) => onChange("lastName", e)}
                value={credentials.lastName}
                size="large"
                placeholder="Ditt efternamn"
                label="Efternamn"
                showLabel
              />
            </div>
            <div>
              <Input
                type="password"
                onChange={(e) => onChange("password", e)}
                value={credentials.password}
                placeholder="Välj lösenord"
                size="large"
                label="Lösenord"
                showLabel
              />
            </div>
            <div>
              <Input
                type="password"
                onChange={(e) => onChange("password_confirmation", e)}
                value={credentials.password_confirmation}
                size="large"
                placeholder="Bekräfta lösenord"
                label="Bekräfta lösenord"
                showLabel
              />
            </div>
            <Button type="submit" size="large">
              Skapa konto
            </Button>

            {Object.values(errors).map((error) =>            
            <p>{error[0]}</p>
            )}
          </div>
        </form>
      </div>
    </div>
  )
}

export default Register
