import { create } from "zustand"
import { shallow } from "zustand/shallow"
import { createWithEqualityFn } from "zustand/traditional"

export const useStore = createWithEqualityFn(
  (set) => ({
    // Total parking
    totalParking: null,
    setTotalParking: (value) => set({ totalParking: value }),

    // Total required
    totalRequired: null,
    setTotalRequired: (value) => set({ totalRequired: value }),

    // Delta
    delta: null,
    setDelta: (value) => set({ delta: value }),

    // Disabled parking
    disabledParking: null,
    setDisabledParking: (value) => set({ disabledParking: value }),

    // Guest parking
    guestParking: null,
    setGuestParking: (value) => set({ guestParking: value }),

    // Total CO2
    totalCo2: null,
    setTotalCo2: (value) => set({ totalCo2: value }),

    // Total Cost
    totalCost: null,
    setTotalCost: (value) => set({ totalCost: value }),
  }),
  shallow,
)
