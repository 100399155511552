import { useState, useEffect } from "react"
import { Container, Row, Col, Nav } from "react-bootstrap"
import Map from "../modules/Map"
import Project from "../modules/Project"
import { initPolicy, initProjects } from "../modules/Api"
import DeleteProjectModal from "../modules/DeleteProjectModal"
import Layout from "../modules/Layout"
import HomeScreen from "../modules/HomeScreen"

const Dashboard = () => {
  const [policy, setPolicy] = useState()
  const [projects, setProjects] = useState([])
  const [selectedProject, setSelectedProject] = useState()
  const lund = [13.1910073, 55.7046601]
  const [coordinates, setCoordinates] = useState(lund)
  const [isLoadingProjects, setIsLoadingProjects] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      // initPolicy("Lund", setPolicy)
      setIsLoadingProjects(true)
      await initProjects(setProjects)
      setIsLoadingProjects(false)
    }
    fetchData()
  }, [])

  return (
    <Layout>
      {selectedProject ? (
        <div className="project">
          <div className="project__toolbar">
            {/* Right toolbar content */}
            {policy && selectedProject && (
              <Project
                policy={policy}
                id={selectedProject._id}
                projects={projects}
                setProjects={setProjects}
                selectedProject={selectedProject}
                setSelectedProject={setSelectedProject}
                setCoordinates={setCoordinates}
              />
            )}
          </div>
          <div className="project__map" style={{ minHeight: "100vh" }}>
            {policy && <Map policy={policy} coordinates={coordinates} />}
          </div>
        </div>
      ) : (
        <HomeScreen
          initProjects={initProjects}
          projects={projects}
          setProjects={setProjects}
          selectedProject={selectedProject}
          setSelectedProject={setSelectedProject}
          setCoordinates={setCoordinates}
          isLoadingProjects={isLoadingProjects}
        />
      )}
    </Layout>
  )
}

export default Dashboard
