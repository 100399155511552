import cn from "clsx"
import CreateProjectModal from "../CreateProjectModal"
import s from "./HomeScreen.module.scss"
import { useNavigate } from "react-router-dom"
import { LoadingSpinner } from "../common/Icons"

const HomeScreen = ({
  projects,
  setProjects,
  selectedProject,
  setSelectedProject,
  setCoordinates,
  initProjects,
  isLoadingProjects,
}) => {
  const navigate = useNavigate()

  const handleCloseModal = () => {
    initProjects(setProjects)
  }

  const handleSelect = (index) => {
    console.log(projects[index])
    navigate(`/projects/${projects[index]._id}`)
    // setSelectedProject(projects[index])
    // setCoordinates(projects[index].location.coordinates)
  }
  // if (isLoadingProjects) return <div>Loading...</div>
  return (
    <div className={s.container}>
      <div className={s.wrap}>
        <div className={s.heading}>
          <h1>Mina projekt</h1>
          <CreateProjectModal onClose={handleCloseModal} />
        </div>
        <ul className={s.list}>
          {isLoadingProjects && (
            <div>
              <LoadingSpinner></LoadingSpinner>
            </div>
          )}
          {projects &&
            projects.map((project, index) => {
              return (
                <li
                  className={s.item}
                  key={project._id}
                  onClick={() => handleSelect(index)}
                >
                  <h2>{project.propertyIdentifier}</h2>
                  <p>{project.commune}</p>
                  <p>{project.zone}</p>
                </li>
              )
            })}
          {!isLoadingProjects && (
            <li className={cn(s.item, s.createNew)}>
              <div>
                <h2>Skapa projekt</h2>
                <CreateProjectModal onClose={handleCloseModal} />
              </div>
            </li>
          )}
        </ul>
      </div>
    </div>
  )
}

export default HomeScreen
