import s from "./ProjectDetails.module.scss"
import { MdEdit, MdCheck } from "react-icons/md"
import Input from "../../common/Input"
import { useState } from "react"

const ProjectDetails = ({ project, header, onProjectChange }) => {
  const { propertyIdentifier, description, commune, zone } = project
  const [isEditable, setIsEditable] = useState(false)
  const [desc, setDesc] = useState(description)

  const toggleEdit = () => {
    if(isEditable && desc !== description){
      handleEdit()
    }
    setIsEditable(!isEditable)
  }

  const handleEdit = () => {
    onProjectChange({ ...project, description: desc})
  }

  const handleDescriptionChange = (e) => {
    setDesc(e.target.value)
  }

  return (
    <div className="toolbar-box">
      <div className={s.headcontainer}>


      <h4 style={{display:"inline"}} className="">{header}</h4>
      <button className={isEditable ? "btn btn-sm btn-success": "btn btn-sm btn-light"} onClick={toggleEdit}>
            {isEditable ? (<MdCheck/>) : (<MdEdit/>)}
      </button>
      </div>
      <div className={s.container}>
        <div>
          <div className="subtitle">Egenskap</div>
          <div>Fastighet</div>
        </div>

        <div>
          <div className="subtitle">Värde</div>
          <div>{propertyIdentifier}</div>
        </div>

        <div>
          <div className="subtitle">Kommun</div>
          <div>{commune}</div>
        </div>

        <div>
          <div className="subtitle">Zon</div>
          <div>{zone}</div>
        </div>

        
        <div>
          <div style={{display:"inline", paddingRight:"4px"}} className="subtitle">Beskrivning</div>
            <div>
              {isEditable 
              ?(
                <Input                  
                type="text" 
                className="input-text"                         
                invertColor
                transparentBackground
                placeholder="Beskrivning"
                value={desc}
                onChange={(e) => handleDescriptionChange(e)} ></Input>
              )
              :(
                <div>{desc}</div>
              )}

              
            </div>
        </div>
      </div>
    </div>
  )
}

export default ProjectDetails
