import ReactDOM from "react-dom/client"
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom"
import { useState, useEffect } from "react"

import LandingPage from "../pages/LandingPage"
import Dashboard from "../pages/Dashboard"
import Project from "../pages/Project"
import LoginPage from "../pages/LoginPage"
import Logout from "../pages/Logout"
import Registration from "../pages/Registration"
import AuthService from "../modules/AuthService"

const RequireAuth = ({ children }) => {
  const loggedIn = AuthService.getToken()
  if (!loggedIn) {
    if (window.location.hostname.includes("localhost")) {
      window.location.href = "/login"
    } else {
      if (window.location.pathname != "/logout") {
        window.location.href = `https://www.parkyria.se/sign-in?redirect=${encodeURIComponent(window.location.href)}`
      }
    }
  }
  return children
}

const PublicRoutes = () => {
  const [loggedIn, setLoggedIn] = useState(AuthService.getToken())

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={<RequireAuth>{loggedIn && <Dashboard />}</RequireAuth>}
        />
        <Route
          path="projects/:id"
          element={
            <RequireAuth>
              <Project />
            </RequireAuth>
          }
        />
        <Route path="login" element={<LoginPage setLoggedIn={setLoggedIn} />} />
        <Route path="logout" element={<Logout setLoggedIn={setLoggedIn} />} />
        <Route path="register" element={<Registration />} />
      </Routes>
    </BrowserRouter>
  )
}

export default PublicRoutes
