import { useRef, useState } from "react"
import Button from "../../common/Button"
import cn from "clsx"
import CreateProjectModal from "../../CreateProjectModal"
import s from "./ProjectSwitcher.module.scss"
import { MdExpand, MdExpandMore } from "react-icons/md"
import Dropdown from "../../common/Dropdown"

const ProjectSwitcher = ({
  currentProject,
  projects,
  setSelectedProject,
  setCoordinates,
  handleCloseModal,
}) => {
  const projectSwitcherRef = useRef()
  const [toggleDropdown, setToggleDropdown] = useState(false)

  // const close = useCallback(() => setToggleDropdown(false))
  // useClickOutside(projectSwitcherRef, close)

  const handleSelect = (index) => {
    setSelectedProject(projects[index])
    setCoordinates(projects[index].location.coordinates)
  }

  return (
    <div className={s.container} ref={projectSwitcherRef}>
      <Button
        outline
        invertColor
        icon={<MdExpandMore />}
        onClick={() => setToggleDropdown(!toggleDropdown)}
      >
        {currentProject?.propertyIdentifier}
      </Button>

      <Dropdown show={toggleDropdown}>
        {projects &&
          projects.map((project, index) => {
            return (
              <li
                className={s.projectItem}
                // className={cn(currentProject._id == project._id && s.isActive)}
                key={project._id}
                onClick={() => {
                  handleSelect(index)
                  setToggleDropdown(false)
                }}
              >
                {project.propertyIdentifier}
              </li>
            )
          })}

        <li>
          <CreateProjectModal
            onClose={() => {
              handleCloseModal()
              setToggleDropdown(false)
            }}
          />
        </li>
      </Dropdown>
    </div>
  )
}

export default ProjectSwitcher
