import cn from "clsx"
import { initProjects } from "../../Api"
import DeleteProjectModal from "../../DeleteProjectModal"
import ProjectSwitcher from "../ProjectSwitcher"
import s from "./ProjectHeader.module.scss"
import { LoadingSpinner } from "../../common/Icons"
import { FaInfoCircle } from "react-icons/fa"

const ProjectHeader = ({
  currentProject,
  projects,
  setProjects,
  setSelectedProject,
  setCoordinates,
  hasSaved,
  editDisabled,
}) => {
  const handleCloseModal = () => {
    initProjects(setProjects)
  }

  return (
    <>
      {editDisabled && (
        <div
          style={{
            background: "#333333",
            margin: "2em",
            padding: "1em",
            borderRadius: "1em",
          }}
        >
          <p style={{ marginBottom: 0, color: "#fff" }}>
            <FaInfoCircle
              fontSize="18px"
              style={{ color: "#A5EB50", marginRight: "0.25em" }}
            />{" "}
            Du har endast tillgång till visningsläget och kan ej redigera.
          </p>
        </div>
      )}
      <div className={cn(s.container, "toolbar-box")}>
        <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
          <ProjectSwitcher
            setCoordinates={setCoordinates}
            currentProject={currentProject}
            projects={projects}
            setSelectedProject={setSelectedProject}
            handleCloseModal={handleCloseModal}
          />
          {hasSaved && (
            <div className={s.saveFeedback}>
              <LoadingSpinner />
              <div
                className="subtitle"
                style={{
                  fontWeight: "bold",
                  display: "inline",
                  fontSize: "16px",
                  marginLeft: "0.5em",
                }}
              >
                {" "}
                Sparar...
              </div>
            </div>
          )}
        </div>

        <DeleteProjectModal
          projectId={currentProject?._id}
          onClose={handleCloseModal}
          editDisabled={editDisabled}
        />
      </div>
    </>
  )
}

export default ProjectHeader
