import { useState } from "react"
import s from "./Accordion.module.scss"
import { MdExpandLess, MdExpandMore } from "react-icons/md"

const Accordion = ({ children, title, expanded }) => {
  const [toggle, setToggle] = useState(expanded == null ? true : false)

  return (
    <div>
      <div className={s.container}>
        <h4 className="mt-10">{title}</h4>
        <button div className={s.toggle} onClick={() => setToggle(!toggle)}>
          {toggle ? "Dölj" : "Visa"}
          <div className={s.button}>
            {toggle ? <MdExpandLess /> : <MdExpandMore />}
          </div>
        </button>
      </div>
      {toggle && <>{children}</>}
    </div>
  )
}

export default Accordion
