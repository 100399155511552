import cn from "clsx"
import { useEffect, useState } from "react"
import { initProjectAndZone, saveProject, initPolicy } from "../modules/Api"
import { LoadingSpinner } from "../modules/common/Icons"
import Construction from "../modules/project/Construction"
import Parking from "../modules/project/Parking"
import ProjectDetails from "../modules/project/ProjectDetails"
import ProjectHeader from "../modules/project/ProjectHeader"
import Reductions from "../modules/project/Reductions"
import Results from "../modules/project/Results"
import Notes from "../modules/project/Notes"
import Layout from "../modules/Layout"
import { useParams } from "react-router-dom"
import Map from "../modules/Map"
import AuthService from "../modules/AuthService"

const Project = ({
  projects,
  setProjects,
  setSelectedProject,
  setCoordinates,
}) => {
  const [hasSaved, setHasSaved] = useState(false)
  const [project, setProject] = useState()
  const [policy, setPolicy] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [editDisabled, setEditDisabled] = useState(false)
  const [zone, setZone] = useState()
  let { id } = useParams()

  useEffect(() => {
    initProjectAndZone(id, setProject, setZone, setPolicy)
    // initPolicy("Lund", setPolicy)

    setIsLoading(false)
  }, [id])

  useEffect(() => {
    if (project && project.projectOwnerId) {
      const user = AuthService.getUser()
      const projectCreator = project.projectOwnerId
      if (user !== projectCreator) {
        setEditDisabled(true)
      }
    }
  }, [project])

  const onProjectChange = (newProject) => {
    saveProject(newProject, setProject)
    setHasSaved(true)
  }

  useEffect(() => {
    const timeOut = setTimeout(() => {
      setHasSaved(false)
    }, 800)

    return () => {
      clearTimeout(timeOut)
    }
  }, [hasSaved])

  useEffect(() => {
    setIsLoading(true)

    const timeOut = setTimeout(() => {
      setIsLoading(false)
    }, 600)

    return () => {
      clearTimeout(timeOut)
    }
  }, [id])

  if (isLoading) {
    return <LoadingSpinner />
  }

  return (
    <Layout>
      <div className="project">
        <div className="project__toolbar">
          <Results />

          <div className="toolbar-inner">
            <ProjectHeader
              editDisabled={editDisabled}
              currentProject={project}
              projects={projects}
              setProjects={setProjects}
              setSelectedProject={setSelectedProject}
              setCoordinates={setCoordinates}
              hasSaved={hasSaved}
            />

            <div className={cn("toolbar-content", isLoading && "is-loading")}>
              {isLoading && (
                <div className="toolbar-loading">
                  <LoadingSpinner />
                </div>
              )}

              {project && zone && (
                <ProjectDetails project={project} header="Projekt" />
              )}
              {project && zone && (
                <Notes
                  project={project}
                  editDisabled={editDisabled}
                  onProjectChange={onProjectChange}
                />
              )}
              {project && zone && (
                <Construction
                  zone={zone}
                  project={project}
                  onProjectChange={onProjectChange}
                  editDisabled={editDisabled}
                />
              )}
              {project && zone && (
                <Reductions
                  project={project}
                  zone={zone}
                  onProjectChange={onProjectChange}
                  editDisabled={editDisabled}
                />
              )}
              {project && zone && (
                <Parking
                  project={project}
                  onProjectChange={onProjectChange}
                  editDisabled={editDisabled}
                />
              )}
            </div>
          </div>
        </div>
        <div className="project__map" style={{ minHeight: "100vh" }}>
          {policy && project && (
            <Map policy={policy} coordinates={project.location?.coordinates} />
          )}
        </div>
      </div>
    </Layout>
  )
}

export default Project
