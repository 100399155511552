import { useState } from "react"
import { useNavigate } from "react-router-dom"
import AuthService from "../AuthService"
import Input from "../common/Input"
import Button from "../common/Button"
import s from "./Login.module.scss"
import { Link } from "react-router-dom"
import Register from "../Register"
import cn from "clsx"
import Message from "../common/Message"

// URL to access register form = login?register=true

const Login = ({ setLoggedIn }) => {
  // Check if URL contains register and display register form
  const queryParameters = new URLSearchParams(window.location.search)
  const isRegister = queryParameters.get("register") === "true"

  const [showRegister, setShowRegister] = useState(isRegister)

  const [credentials, setCredentials] = useState({ email: "", password: "" })
  const [error, setError] = useState("")
  const navigate = useNavigate()

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      await AuthService.login(credentials)
      // await AuthService.verifyToken()
      // Redirect to home on successful verification
      setLoggedIn(AuthService.getToken())
      navigate("/")
    } catch (error) {
      console.log("error!", error)
      setError("Du har angivit ett felaktigt användarnamn eller lösenord")
    }
  }

  const onChange = (type, e) => {
    setCredentials((old) => ({
      ...old,
      [type]: e.target.value,
    }))
  }

  return (
    <div className={s.container}>
      <div className={s.wrap}>
        <div className={s.form}>
          <div className={s.navigation}>
            <ul>
              <li>
                <button onClick={() => setShowRegister(false)}>Logga in</button>
              </li>
              <li>
                <button onClick={() => setShowRegister(true)}>
                  Registrera konto
                </button>
              </li>
            </ul>
            <div className={cn(s.activeMarker, showRegister && s.isActive)} />
          </div>
          {showRegister ? (
            <>
              {isRegister ? (
                <Register setShowRegister={setShowRegister} animate />
              ) : (
                <div>
                  <Message style={{ backgroundColor: "#fff" }}>
                    <div>
                      <h4>Vill du testa Parkyria?</h4>
                      Kontakta oss så hjälper vi dig att komma igång!
                      <a
                        href="mailto:adetoun@urbanivation.com"
                        style={{ marginTop: "1.5em", display: "block" }}
                      >
                        <Button>Kontakta oss</Button>
                      </a>
                    </div>
                  </Message>
                </div>
              )}
            </>
          ) : (
            <>
              <h1 className={s.title}>Välkommen tillbaka!</h1>
              <form onSubmit={handleSubmit}>
                <div className="form-grid form-grid--large-gutter">
                  <div>
                    <Input
                      type="email"
                      onChange={(e) => onChange("email", e)}
                      value={credentials.username}
                      placeholder="Ange e-post"
                      label="E-post"
                      showLabel
                      size="large"
                    />
                  </div>
                  <div>
                    <Input
                      type="password"
                      onChange={(e) => onChange("password", e)}
                      value={credentials.password}
                      placeholder="Ange lösenord"
                      label="Lösenord"
                      showLabel
                      size="large"
                    />
                  </div>
                  <Button type="submit" size="large">
                    Logga in
                  </Button>

                  {error && <Message type="error">{error}</Message>}

                  <div className={s.register}>
                    Inte medlem?
                    <button onClick={() => setShowRegister(true)}>
                      Registrera konto
                    </button>
                  </div>
                </div>
              </form>
            </>
          )}
        </div>
      </div>

      <div className={s.image}>
        <img
          src="/login-image.jpg"
          width="1280"
          height="1440"
          loading="lazy"
          alt="Login bild"
        />
      </div>
    </div>
  )
}

export default Login
